.home {
  background: linear-gradient(180deg, #FFF0DF 0%, #FFF0DF 100%);
  position: relative;
}
.leaderboard_wrapper {
  display: flex;
  flex-direction: column;
}

.leaderboard_list {
  display: flex;
  padding: 14px 2px;
  border-bottom: 1px solid #383838;
  align-items: center;
  width: 100%;
  column-gap: 25px;
}

.leaderboard_list_main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leaderboard_sr_no {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.leaderboard_sr_no_skelton {
  width: 10px;
  height: 15.75px;

  background: linear-gradient(
      270deg,
      #2b2b2b 0%,
      rgba(37, 37, 37, 0.05) 100%
  );
  border-radius: 22.5px;
}

.leaderboard_profile_box {
  display: flex;
  align-items: center;
  column-gap: 10px;
  /* min-width: 250px; */
}

.leaderboard_leader_profile_wrapper {
  overflow: hidden;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background: rgba(204, 204, 204, 0.193);
}

.home-top {
  padding: 100px 100px;
  height: auto;
  justify-content: center;
  gap: 8rem;
}
.banner {
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 20px;
}
.home-top-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-top-txt-heading {
  font-family: "Lobster";
  font-style: normal;
  color: #960d0d
}
.home-top-txt-para {
  letter-spacing: 0.01em;
  color: #960d0d;
  width: 587px;
}
.home-top-txt-cta {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  background-color: transparent;
  color: #960d0d;
  border-style: solid;
  border-width: 4px;
  text-align: center;
  border-radius: 20px;
  white-space: nowrap;
}
.home-top-img {
  width: 473px;
  object-fit: contain;
}
.home-top-img-img {
  width:fit-content;
  border-radius: 10px;
  position: absolute;
  z-index: 0;
}
.home-top-img-controls {
  position: absolute;
  width: 100%;
  z-index: 1;
}
.home-top-img-control-back {
  width: 80px;
  cursor: pointer;
}
.home-top-img-control-forward {
  width: 80px;
  transform: rotate(180deg);
  cursor: pointer;
}
.home-top-img-cta {
  font-family: "Montserrat";
  bottom: 20px;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  width: 430px;
}
.marquee {
  height: 120px;
  background: #ffffff;
}
.marquee-con {
  display: flex;
  gap: 20px;
}
.marquee-content {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 30px;
  width: min-content;
}
.marquee-dot {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background-color: #960D0D;
}
.marquee-txt {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  color: black;
  white-space: nowrap;
}
.home-btm {
  background-color: #FFF0DF;
  display: flex;
  align-items: center;
}
.home-btm-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  color: #960d0d;
  text-align: center;
}
.home-btm-img-dsiplay {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.home-btm-img {
  width: 292px;
  object-fit: contain;
  cursor:auto;
}
.home-top-bg {
  display: none;
}
.home-btm-bg {
  display: none;
}
.home-top-bg-mob {
  display: none;
}

@media screen and (max-width: 1300px) {
  .home-top {
    padding: 0px 40px;
  }
}
@media screen and (max-width: 1200px) {
  .home-top-img-img {
    border-radius: 16px;
  }
  .home-top-img-cta {
    width: 360px;
  }
}
@media screen and (max-width: 1100px) {
  .home-top-txt-para {
    width: 500px;
  }
}
@media screen and (max-width: 1250px) {
  .home-top-bg-mob {
    display: flex;
    position: absolute;
    width: 90vw;
    top: 45px;
    z-index: 0;
  }
  .home-top-bg {
    display: none;
  }
  .home-top {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 32px;
    height: auto;
    padding: 40px;
  }
  .home-top-txt-cta {
    display: flex;
    border-width: 2px;
  }
  .home-top-img-cta {
    width: calc(60vw - 80px);
  }
  .home-btm-img-dsiplay {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
@media screen and (max-width: 769px) {
  .home-top-img-control-back {
    width: 50px;
    cursor: pointer;
  }
  .home-top-img-control-forward {
    width: 50px;
    transform: rotate(180deg);
    cursor: pointer;
  }
  .marquee-txt {
    font-size: 14px;
    line-height: 18px;
    color: black
  }
  .marquee-content {
    width: min-content;
    gap: 12px;
  }

  .marquee-con {
    gap: 12px;
  }
  .marquee-dot {
    width: 12px;
    height: 12px;
  }
  .home-top-img-img {
    width: 240px;
    border-radius: 16px;
  }
  .home-top-img {
    margin-top: 20px;
  }
  .home-top-img-cta {
    width: calc(100vw - 76px);
  }
  .home-top-txt-para {
    margin-top: 32px;
  }
  .marquee {
    height: 72px;
    overflow: hidden;
  }
  .home {
    height: unset;
  }
  .home-btm-img {
    width: calc(50vw - 27px);
    height: unset;
  }
  .home-btm {
    padding: 30px 18px;
  }
  .home-btm-img-dsiplay {
    gap: 14px;
    grid-template-columns: 1fr 1fr;
  }
  .home-top {
    padding: 20px;
  }
  .home-top-txt-heading {
    width: calc(100vw - 80px);
    font-size: 36px;
    text-align: center;
  }
  .home-top-txt-para {
    width: calc(100vw - 80px);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    /* or 29px */
    letter-spacing: -0.01em;
    margin-top: 10px;
    text-align: center;
  }
}
@media screen and (max-width: 500px){
  .home-top-img{
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .home-top-img-cta {
    padding: 10px;
  }
}
@media screen and (max-width: 370px) {
  .home-top-img-cta {
    bottom: 16px;
  }
}
