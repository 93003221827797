.card-modal {
  position: relative;
}
.card-modal-main {
  z-index: 2;
  background-color: #FFF0DF;
}
.card-modal-img-img {
  width: 100%;
  object-fit: cover;
}
.card-modal-img-img-2 {
  width: 100%;
}
.card-modal-img {
  /* border: 2px solid black; */
  /* top: -22.5vw; */
  width: 45vw;
  position: relative;
}
.card-modal-img-back {
  position: absolute;
  z-index: 20;
}
.card-modal-txt {
  display: flex;
  flex-direction: column;
}
.card-modal-txt-img {
  padding-bottom: 15px;
  width: 100%;
}
.card-modal-txt-title {
  font-family: "Montserrat";
  font-size: 36px;
  line-height: 120%;
  /* or 43px */
  letter-spacing: 0.02em;
  color: #000000;
}
.car-modal-txtxt {
  padding: 0px 40px;
  gap: 14px;
}
.card-modal-txt-para {
  font-family: "Montserrat";
  color: #000000;
}
.card-modal-txt-para-select {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
.card-modal-txt-choices {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 18px;
}
.card-modal-txt-choice1 {
  height: 121px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.card-modal-txt-choice2 {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-color: #960d0d;
}
.card-modal-txt-choice1-img {
  width: 61px;
}
.card-modal-txt-choice2-img {
  width: 100px;
}
.modal-txt-but {
  font-family: "Montserrat";
  font-style: normal;
  border-radius: 8px;
  margin: 28px 0px;
  cursor: pointer;
  color: white;
  background-color: #960d0d;
}

/* image styles */
.apparel {
  position: absolute;
  top: 0;
}
.background {
  border-radius: 8px;
  position: absolute;
}
.earrings {
  position: absolute;
  z-index: 12;
  width: 45vw;
  top: 0;
}
.eyes {
  position: absolute;
  z-index: 6;
  width: 45vw;
  top: 0;
}
.eyewear {
  position: absolute;
  z-index: 13;
  width: 45vw;
  top: 0;
}
.face {
  position: absolute;
  z-index: 5;
  width: 45vw;
  top: 0;
}
.forehead {
  position: absolute;
  z-index: 15;
  width: 45vw;
}
.hairstyle {
  position: absolute;
  top: 0;
}
.head {
  position: absolute;
  top: 0;
}
.lips {
  position: absolute;
  top: 0;
}
.necklace {
  position: absolute;
}
.nose {
  position: absolute;
  top: 0;
}
.skin {
  position: absolute;
  z-index: 4;
  width: 45vw;
  top: 0;
}
.tattoo {
  position: absolute;
  z-index: 9;
  width: 45vw;
  top: 0;
}

@media screen and (max-width: 1200px) {
  
  .apparel {
    width: 40vw;
  }
  .background {
    width: 40vw;
  }
  .earrings {
    width: 40vw;
  }
  .eyes {
    width: 40vw;
  }
  .eyewear {
    width: 40vw;
  }
  .face {
    width: 40vw;
  }
  .forehead {
    width: 40vw;
  }
  .hairstyle {
    width: 40vw;
  }
  .head {
    width: 40vw;
  }
  .lips {
    width: 40vw;
  }
  .necklace {
    width: 40vw;
  }
  .nose {
    width: 40vw;
  }
  .skin {
    width: 40vw;
  }
  .tattoo {
    width: 40vw;
  }

  .card-modal-txt {
    width: 45vw;
  }
  .card-modal-img {
    width: 40vw;
  }
}
@media screen and (max-width: 1000px) {
  .card-modal-main{
    padding: 40px;
  }
  .card-modal-img {
    height: calc(100vw - 80px);
    top: 1vw;
  }
  .apparel {
    width: calc(100vw - 80px);
  }
  .background {
    width: calc(100vw - 80px);
  }
  .earrings {
    width: calc(100vw - 80px);
  }
  .eyes {
    width: calc(100vw - 80px);
  }
  .eyewear {
    width: calc(100vw - 80px);
  }
  .face {
    width: calc(100vw - 80px);
  }
  .forehead {
    width: calc(100vw - 80px);
  }
  .hairstyle {
    width: calc(100vw - 80px);
  }
  .head {
    width: calc(100vw - 80px);
  }
  .lips {
    width: calc(100vw - 80px);
  }
  .necklace {
    width: calc(100vw - 80px);
  }
  .nose {
    width: calc(100vw - 80px);
  }
  .skin {
    width: calc(100vw - 80px);
  }
  .tattoo {
    width: calc(100vw - 80px);
  }

  .card-modal-main {
    flex-direction: column;
    gap: 32px;
  }
  .card-modal-img {
    width: calc(100vw - 80px);
  }
  .card-modal-txt {
    width: calc(100vw - 80px);
  }
}
@media screen and (max-width: 560px) {
  .card-modal-main{
    padding: 20px;
  }
  .card-modal-img {
   height: calc(100vw - 40px);
  }
  .apparel {
    width: calc(100vw - 40px);
  }
  .background {
    width: calc(100vw - 40px);
  }
  .earrings {
    width: calc(100vw - 40px);
  }
  .eyes {
    width: calc(100vw - 40px);
  }
  .eyewear {
    width: calc(100vw - 40px);
  }
  .face {
    width: calc(100vw - 40px);
  }
  .forehead {
    width: calc(100vw - 40px);
  }
  .hairstyle {
    width: calc(100vw - 40px);
  }
  .head {
    width: calc(100vw - 40px);
  }
  .lips {
    width: calc(100vw - 40px);
  }
  .necklace {
    width: calc(100vw - 40px);
  }
  .nose {
    width: calc(100vw - 40px);
  }
  .skin {
    width: calc(100vw - 40px);
  }
  .tattoo {
    width: calc(100vw - 40px);
  }
  .card-modal-img {
    width: calc(100vw - 40px);
  }
  .card-modal-txt {
    width: calc(100vw - 40px);
  }
  .card-modal-txt-choice1 {
    width: 95px;
    height: 95px;
  }
  .card-modal-txt-choice2 {
    width: 95px;
    height: 95px;
  }
  .card-modal-txt-choices {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .card-modal-txt-para {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
    letter-spacing: 0.01em;
    color: #000000;
  }
}

.card_outer_body_container {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 250px;
  min-height: 350px;
  color: white;
  position: relative;
  transition: all 0.3s linear;
}

.card_outer_body {
  display: flex;
  flex-direction: column;
  width: 250px;
  color: white;
  cursor: pointer;
  height: 100%;
  position: relative;
}
.card_image {
  height: 200px;
  min-height: 208px;
  background: linear-gradient(
      270deg,
      #2b2b2b 0%,
      rgba(37, 37, 37, 0.05) 100%
  );
  overflow: hidden;
  width: 100%;
  position: relative;
}

.card_content {
  padding: 15px 20px;
  background: linear-gradient(
      270deg,
      #2b2b2b 0%,
      rgba(37, 37, 37, 0.05) 100%
  );
  box-shadow: 0px 0.99063px 7.92504px rgba(110, 110, 110, 0.1);
  border-top: 1px solid #2b2b2b;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
  color: #171a1e;
  min-height: 121px;
  height: 100%;
  justify-content: space-between;
}

.text_skelton {
  width: 179px;
  height: 20.35px;
  margin-bottom: 10px;

  background: linear-gradient(
      270deg,
      #2b2b2b 0%,
      rgba(37, 37, 37, 0.05) 100%
  );
  border-radius: 2px;
}

/* Media Queries */
@media (max-width: 500px) {
  .card_outer_body_container {
      width: 100%;
  }

  .card_outer_body {
      width: 100%;
  }

  .card_image {
      height: 320px;
      min-height: 320px;
  }

  .card_content h1 {
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
  }
}
