* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600;700&display=swap');
@import url('https://res.cloudinary.com/bootstrapv6/raw/upload/v1671585748/index.css');
@import url('https://fonts.googleapis.com/css2?family=Festive&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Lobster&display=swap');

.opacityToggle {
  opacity: 0;
  animation: opacityTG 2s infinite;
}

@keyframes opacityTG {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
