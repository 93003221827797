.my-nft-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.my-nft-card-img {
  width: 390px;
}
.my-nft-card-btm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  padding: 18px 24px;
}
.my-nft-card-btm-txt {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
  letter-spacing: 0.02em;
  color: #000000;
}
.my-nft-card-btm-img{
    width: 36px;
    transform: rotate(180deg);
}
@media screen and (max-width:570px) {
    .my-nft-card-img {
        width: calc(100vw - 40px);
    }
}