.wallet-card {
  background: #ffffff;
  box-shadow: 0px 2px 53px rgba(180, 180, 180, 0.12);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 40px 24px;
  cursor: pointer;
  transition: all 0.3s linear;
}
.wallet-card-2{
  background: #ffffff;
  box-shadow: 0px 2px 53px rgba(180, 180, 180, 0.12);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 40px 24px;
  cursor: pointer;
  transition: all 0.3s linear;
}
.wallet-card-2:hover{
  background: #d9004b17
}
.wallet-card:hover{
  background: #d9004b17
}
.wallet-card-img {
  width: 60px;
}
.wallet-card-txt {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 282px;
}
.wallet-card-txt-heading {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height, or 130% */
  text-align: center;
  color: #1f1f2c;
}
.wallet-card-txt-para {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */
  text-align: center;
  color: #7a798a;
}
@media screen and (max-width: 600px) {
  .wallet-card {
    width: calc(100vw - 40px);
  }
  .wallet-card-2{
    width: calc(100vw - 40px);
  }
}
