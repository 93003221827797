.my8sian {
  position: relative;
}
.my8sian-con {
  gap: 28px;
  padding: 100px;
  padding-top: 0px;
  position: relative;
  z-index: 1;
  background-color: #FFF0DF; 
}
.modal_background {
  background: linear-gradient(160.04deg, #1a1e24 -3.18%, #11100f 102.06%);
  box-shadow: 0px 0.851942px 6.81553px rgba(110, 110, 110, 0.1);
}

.button_heading {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}

.button_design {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px 8px;
  gap: 6px;
  width: 243px;
  height: 46px;
  background: linear-gradient(90deg, #f19536 0%, #ec5d29 100%);
  border-radius: 2px;

  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #ffffff;

  border: 1px solid #ed6843;
}

.button_design:hover {
  background: transparent;
  border: 1px solid #ed6843;

  background: linear-gradient(90deg, #f19536 0%, #ec5d29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.my8sian-header {
  font-family: "Montserrat";
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  color: #960d0d;
  background-color: #FFF0DF;
}
.home-top-bg-mob2 {
  display: none;
}
@media screen and (max-width: 768px) {
  .home-top-bg-mob2 {
    display: flex;
    position: absolute;
    top: 40px;
    width: 100%;
  }
}
@media screen and (max-width: 570px) {
  .my8sian-header {
    padding: 30px 20px;
  }
  .my8sian-con {
    padding: 20px 20px;
  }
}
@media screen and (max-width: 400px) {
    .my8sian-header {
        font-size: 36px;
    }
}
