.con-wall {
  background: #fff0df;
  position: relative;
}
.con-top-bg {
  position: absolute;
  width: 100%;
}
.con-wal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.con-wal-box {
  position: relative;
  width: 70vw;
}

.modal_wrapper {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(50px);
  outline: none;
  position: relative;
  /* border-radius: 8px; */
  display: flex;
  justify-content: space-between;
  /* animation: 0.2s animateDown ease-in-out; */
  /* transform: translateY(0px);
  animation-duration: 500ms; */
  border-top: 1px solid #1e1e1e;
}

@keyframes animateDown {
  0% {
      transform: translateY(-400px);
      opacity: 0;
  }
  50% {
      transform: translateY(200px);
  }
  100% {
      transform: translateY(0px);
      opacity: 1;
  }
}

@keyframes animateDownMobile {
  0% {
      transform: translateY(-400px);
      opacity: 0;
  }
  50% {
      transform: translateY(200px);
  }
  100% {
      transform: translateY(0px);
      opacity: 1;
  }
}

.left_container {
  min-width: 400px;
  width: 400px;
  height: 100%;
  overflow: hidden;
  flex: 0.4;
  padding: 40px 100px;
  padding-right: 50px;
}

.image_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.image_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.raffleType_raffle {
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 150px;
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 14px 0px 0px;
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.content_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  overflow: scroll;
  flex: 0.6;
}

.top_details_content {
  padding: 40px 100px;
  padding-left: 50px;
  padding-bottom: 20px;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow-y: scroll;
}

.detail_list_body {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.bottom_details_content {
  padding: 40px 100px;
  padding-top: 10px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Heading */
.content_wrapper h1 {
  font-family: 'Nofex';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 31px;
  display: flex;
  align-items: center;

  background: linear-gradient(90deg, #f19536 0%, #ec5d29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.top_title {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

/* Sub Heading */
.sub_heading {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  letter-spacing: 0.00851942px;
}

.sub_heading .color_orange {
  background: linear-gradient(90deg, #f19536 0%, #ec5d29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.detail_list_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.detail_list_heading {
  display: flex;
  column-gap: 20px;
  overflow-x: scroll;
  white-space: nowrap;
}

.list_name {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-bottom: 1px solid transparent;
}

.list_name:disabled {
  cursor: default;
}

.list_name.list_name_active {
  background: linear-gradient(90deg, #f19536 0%, #ec5d29 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  border-bottom: 1px solid #f19536;
}

/* Description */
.content_wrapper .description {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  letter-spacing: 0.00851942px;
}

.row_content {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 50px;
  row-gap: 5px;
  flex-wrap: wrap;
}

.col_content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 10px;
  column-gap: 30px;
  justify-content: space-between;
}

.category_wrapper_container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.con-wal-box-con {
  display: flex;
  gap: 12px;
  width: 600px;
}
.con-wal-box-con-h {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height, or 122% */
  text-align: center;
  text-transform: capitalize;
  color: #1f1f2c;
}
.con-wal-box-con-p {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #7a798a;
}
.con-wal-box-box {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.back-con-wall {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 32px;
  color: #000000;
  z-index: 4;
  cursor: pointer;
}
.con-top-bg-mob {
  display: none;
}
@media screen and (max-width: 900px) {
  .con-wal-box-con {
    width: 500px;
  }
}
@media screen and (max-width: 768px) {
  .con-top-bg {
    display: none;
  }
  .con-top-bg-mob {
    display: flex;
    width: 100%;
    top: 48px;
  }
  .con-wal-box {
    background-color: transparent;
    width: 100vw;
  }
  .back-con-wall {
    background: #ffffff;
    border-radius: 50px;
  }
  .con-wal-box-con-h {
    color: black;
  }
  .con-wal-box-con-p {
    color: black;
  }
}
@media screen and (max-width: 510px) {
  .con-wal-box-con {
    width: calc(100vw - 40px);
  }
  .back-con-wall {
    top: -8px;
  }
}
