.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
}
.footer-img {
  width: 100%;
}
.footer-main {
  background-color: white;
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 100px;
}
.footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 200px;
}
.footer-icon {
  font-size: 30px;
  color: black;
  cursor: pointer;
}
.footer-txt {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  /* identical to box height, or 21px */
  letter-spacing: 0.02em;
  color: black;
}
.footer-prom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 2px;
}
.footer-prom-upper {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  /* identical to box height, or 13px */
  letter-spacing: 0.02em;
  color: black;
}
.footer-prom-lower {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  /* or 18px */
  letter-spacing: 0.02em;
  color: black;
}
.footer-main-mob {
  display: none;
}
.footer-img-mob {
  display: none;
}
@media screen and (max-width: 768px) {
  .footer-img {
    display: none;
  }
  .footer-main {
    display: none;
  }
  .footer-img-mob {
    display: flex;
    width: 100%;
    background-color: #9f0036;
  }
  .footer-main-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 24px;
    background: white;
  }
  .footer-main-mob-top-img {
    width: 84px;
  }
  .footer-links-mob {
    display: flex;
    gap: 36px;
    justify-content: center;
    align-items: center;
  }
  .footer-main-mob-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(256, 256, 256, 0.2);
    width: 100%;
  }
  .footer-icon-mob {
    font-size: 20px;
    color: black;
  }
  .footer-main-mob-btm {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
  }
}
@media screen and (max-width: 420px) {
  .footer-txt {
    font-size: 12px;
  }
  .footer-prom-upper {
    font-size: 12px;
  }
  .footer-prom-lower {
    font-size: 12px;
  }
}
