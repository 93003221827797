.navbar {
  display:flex;
  justify-content: space-between;
  padding: 0px 40px;
  align-items: center;
  height: 70px;
  background: white;
  backdrop-filter: blur(2px);
  position: relative;
  z-index: 4;
}
.navbar-logo {
  width: 110px;
  cursor: pointer;
}
.navbar-connect{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */
  letter-spacing: 0.02em;
  padding: 12px 36px;
  background: #ffffff;
  text-align: center;
  background-color: #960d0d;
  color: white;
  border-style: solid;
  border-width: 4px;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
}
.balancingdiv{
  width: 190px;
}
.navbar-menu {
  display: none;
}
.navbar-menu2 {
  display: none;
}
@media screen and (max-width: 1000px) {
  .balancingdiv{
    display: none;
  }
  .navbar-connect{
    display: none;
  }
  .navbar-menu {
    display: flex;
    font-size: 32px;
    color: black;
    cursor: pointer;
  }
  .navbar-menu2 {
    display: flex;
    font-size: 32px;
    color: black;
    cursor: pointer;
  }
  .navbar {
    justify-content: space-between;
    padding: 0px 18px;
    height: 65px;
  }
}
.ham-dropdown {
  width: 0vw;
  background-color: #ffffff;
  position: absolute;
  top: 64px;
  height: calc(100vh - 64px);
  z-index: 10;
  right: 0;
  animation: comeLeft 0.7s ease-in-out 1 forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@keyframes comeLeft {
  0% {
    width: 0vw; 
  }
  100% {
    width: 100vw;
  }
}
.ham-dropdown-close{
  width: 100vw;
  background-color: #ffffff;
  position: absolute;
  top: 64px;
  height: calc(100vh - 64px);
  z-index: 10;
  right: 0;
  animation: comeRight 0.7s ease-in-out 1 forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@keyframes comeRight {
  0% {
    width: 100vw; 
  }
  100% {
    width: 0vw;
  }
}
.nav-links-mob {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  margin-bottom: 82px;
  opacity: 0;
  animation: changeOpacity 500ms linear 0.5s 1 forwards;
}
.nav-links-mob-close {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  margin-bottom: 82px;
  opacity: 0;
  animation: changeOpacity2 350ms linear 1 forwards;
}
@keyframes changeOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes changeOpacity2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.nav-link-mob {
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  /* or 43px */

  letter-spacing: 0.02em;

  color: #000000;
}
.btn-cont-mob {
  width: calc(100vw - 38px);
  display: flex;
  justify-content: center;
}
.nav-btn-mob {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */
  letter-spacing: 0.02em;
  color: #ffffff;
  text-align: center;
  width: 94%;
  padding: 16px;
  background: #960d0d;
  border-radius: 20px;
  margin: 28px 0px;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
}
